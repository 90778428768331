import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Privacy from "./privacy";
import Terms from "./terms";

export default function About() {
  return (
    <div className="App">
      <p>
        <img
          src="/images/devcraft.png"
          alt="devcraft"
          style={{
            width: "200px",
            height: "200px",
            float: "left",
            padding: "0 0 1rem 1rem",
            margin: "0 0 1rem 1rem",
          }}
        />
      </p>
      <br/>
      <p>
        <Link to="/privacy">Privacy Policy</Link>
      </p>
      <p>
        <Link to="/terms">Terms and Coditions</Link>
      </p>
    </div>
  );
}
