export default function Privacy() {
  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "800px",
        padding: "0 1rem",
        lineHeight: "1.5",
        textAlign: "justify",
        fontFamily: "sans-serif",
        padding: "2rem",
      }}
    >
      <img
        src="/images/devcraft.png"
        alt="devcraft"
        style={{
          width: "100px",
          height: "100px",
          float: "right",
          margin: "0 0 1rem 1rem",
        }}
      />
      <h1>Privacy Policy</h1>
      <p>
        Privacy Policy Last Updated: 22-Oct-2023
        <br /> <h4>1. Introduction</h4>
        Welcome to <a href="https://www.devcraft.co.in">devcraft</a>{" "}
        ("Website"). This Privacy Policy is designed to help you understand how
        we collect, use, and safeguard your personal information. By accessing
        or using our Website, you agree to the terms of this Privacy Policy.
        <br /> <h4>2. Information We Collect</h4> We do not collect any personal
        information from our users. Our Website is for personal use, and we do
        not store any client data or information.
        <br /> <h4>3. Use of Cookies</h4> We do not use cookies or any tracking
        technologies on our Website.
        <br /> <h4>4. Links to Other Websites</h4> Our Website may contain links
        to other websites that are not under our control. We are not responsible
        for the privacy practices of these websites. Please review their privacy
        policies before providing any personal information.
        <br /> <h4>5. Changes to this Privacy Policy</h4> We may update this
        Privacy Policy from time to time. Any changes will be posted on this
        page, and the date of the last update will be modified accordingly.
        <br /> <h4>6. Contact Information</h4> If you have any questions or
        concerns about this Privacy Policy, you can contact us at Email : 
        rushikesh.korgaonkar@gmail.com
      </p>
    </div>
  );
}
