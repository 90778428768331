import "./styles.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Aboutus from "./components/about";
import Privacy from "./components/privacy";
import Terms from "./components/terms";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Aboutus />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
