export default function Terms() {
  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "800px",
        padding: "0 1rem",
        lineHeight: "1.5",
        textAlign: "justify",
        fontFamily: "sans-serif",
        padding: "2rem",
      }}
    >
      <p>
        <img
          src="/images/devcraft.png"
          alt="devcraft"
          style={{
            width: "100px",
            height: "100px",
            float: "right",
            margin: "0 0 1rem 1rem",
          }}
        />
      </p>
      <h4>Terms and Conditions</h4>
      <p>
        Welcome to <a href="https://www.devcraft.co.in">devcraft</a>. These
        Terms and Conditions ("Terms") govern your use of our website and
        services.
      </p>

      <h4>1. Personal Use Only</h4>
      <p>
        Our website is intended for personal use only. You may not use it for
        any commercial purposes.
      </p>

      <h4>2. No Data Collection</h4>
      <p>
        We do not collect any personal information from our users. Our Website
        is for personal use, and we do not store any client data or information.
      </p>

      <h4>3. Cookies</h4>
      <p>We do not use cookies or any tracking technologies on our Website.</p>

      <h4>4. Links to Other Websites</h4>
      <p>
        Our Website may contain links to other websites that are not under our
        control. We are not responsible for the privacy practices of these
        websites. Please review their privacy policies before providing any
        personal information.
      </p>

      <h4>5. Changes to Terms</h4>
      <p>
        We may update these Terms from time to time. Any changes will be posted
        on this page, and the date of the last update will be modified
        accordingly.
      </p>

      <h4>6. Contact Information</h4>
      <p>
        If you have any questions or concerns about these Terms and Conditions,
        you can contact us at Email : rushikesh.korgaonkar@gmail.com
      </p>
    </div>
  );
}
